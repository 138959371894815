import config from "./config";

export const homePage = () => {
  console.log("home page app");
  return fetch(`${config.baseAPI}/home`).then((res) => res.json());
};

export const productPage = () => {
  console.log("product page app");
  return fetch(`${config.baseAPI}/products`).then((res) => res.json());
};

export const winePage = () => {
  console.log("wine page app");
  return fetch(`${config.baseAPI}/wines`).then((res) => res.json());
};

export const wineDetail = (id) => {
  console.log("wine detail");
  return fetch(config.baseAPI + "/wine/" + id).then((x) => x.json());
};

export const productDetail = (id) => {
  console.log("product detail");
  return fetch(config.baseAPI + "/product/" + id).then((x) => x.json());
};

export const aboutUsPage = () => {
  console.log("about us page app");
  return fetch(`${config.baseAPI}/about`).then((res) => res.json());
};

export const contactUsPage = () => {
  console.log("contact us page app");
  return fetch(`${config.baseAPI}/contact`).then((res) => res.json());
};

export const socialMedia = () => {
  console.log("social media");
  return fetch(`${config.baseAPI}/social-media`).then((res) => res.json());
};

export const searchProduct = (language, text) => {
  const path = `${config.baseAPI}/search/${language}/${text}`;
  console.log(path);
  return fetch(path)
    .then((res) => res.json())
    .catch((e) => console.log(e));
};

export const wrapPromise = (promise) => {
  let status = "pending";
  let result = "";
  let suspender = promise().then(
    (r) => {
      status = "success";
      result = r;
    },
    (e) => {
      status = "error";
      result = e;
    }
  );

  return {
    read() {
      if (status === "pending") {
        throw suspender;
      } else if (status === "error") {
        throw result;
      }

      return result;
    },
  };
};

export const resource = {
  homePage: wrapPromise(homePage),
  aboutUsPage: wrapPromise(aboutUsPage),
  contactUsPage: wrapPromise(contactUsPage),
  productPage: wrapPromise(productPage),
  productDetail: (id) => wrapPromise(() => productDetail(id)),
  winePage: wrapPromise(winePage),
  wineDetail: (id) => wrapPromise(() => wineDetail(id)),
  socialMedia: wrapPromise(socialMedia),
  searchProduct: (language, text) =>
    wrapPromise(() => searchProduct(language, text)),
};

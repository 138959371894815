import React from "react";

export default function CategoryBoxImage({ source, title, filterHandler }) {
  return (
    <div className="col-12 col-md-4 mb-3" onClick={filterHandler}>
      <div
        className="category-box-image"
        style={{ backgroundImage: `url(${source})` }}
      >
        <h2 className="cbi-inner">{title}</h2>
      </div>
    </div>
  );
}

import React from 'react'

const Banner = ({data}) => {
    const {src, title} = data;
    return (
        <div className="banner" style={{"backgroundImage": "url(" + src + ")"}}>
            <h1>{title}</h1>
        </div>
    )
}

export default Banner;
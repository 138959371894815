import React from "react";
import Banner from "../components/Banner";
import Breadcrumb from "../partials/Breadcrumb";
import ProductsIntroduct from "../components/ProductsIntroduct";
import config from "../config";
import ScrollLoadData from "../partials/ScrollLoadData";
import { resource } from "../fetchDataApp";
import { useTranslation } from "react-i18next";
import DocumentMeta from "react-document-meta";

function Products() {
  const serverData = resource.productPage.read();
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const bannerDataItem = {
    src: `${config.baseURL}/storage/${serverData.page.banner}`,
  };

  const breadcrumbData = [
    { title: t("home"), link: "/" },
    { title: t("product_gallery") },
  ];

  window.scrollTo(0, 0);

  const meta = {
    title: t("meta.product.title").toUpperCase(),
    description: t("meta.product.description"),
    meta: {
      name: {
        keywords: t("meta.product.keywords"),
      },
    },
  };

  return (
    <DocumentMeta {...meta}>
      <div className="producst">
        <Banner data={bannerDataItem} />
        <Breadcrumb data={breadcrumbData} />
        <ProductsIntroduct
          subtitle={serverData.page["sub_title_" + language]}
          title={serverData.page["title_" + language]}
          content={serverData.page["content_" + language]}
        />
        <ScrollLoadData serverData={serverData} />
      </div>
    </DocumentMeta>
  );
}

export default Products;

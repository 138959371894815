import React from 'react';
import { Link } from 'react-router-dom'

const Breadcrumb = ({data}) => {
    return (
        <div className="custom-breadcrumb">
            <div className="container">
                {data.map((item, index) => (
                    <span key={index} className="text-capitalize">
                        { item.link ?
                            <Link to={item.link}>
                                { item.title }
                            </Link>
                            :
                            item.title
                        }
                    </span>
                ))}
            </div>
        </div>
    )
}

export default Breadcrumb;

import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import config from "../config";

function ImageBoxCard3({ data }) {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  //console.log("loading image box card");

  return (
    <div className="col-12 col-md-4">
      <div className="image-box-card3 position-relative">
        <div className="mb-2">
          <img
            src={config.baseURL + "/storage/" + data.thumbnail}
            className="img-fluid"
          />
          <div className="ibc3-lightbox">
            <div className="ibc3-inner text-white">
              <div className="mb-1">
                {data.categoryEntity.map((item, index) => (
                  <span key={index}>{item["title_" + language]}</span>
                ))}
              </div>
              <div style={{ fontSize: 20 }}>{data["title_" + language]}</div>
              <p>{data["desc_" + language]}</p>
              <Link
                className="d-inline-block px-5 py-2 bg-white text-dark text-decoration-none"
                to={`/${language}/wine-packaging/${data.id}`}
              >
                {t("view")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageBoxCard3;

import React from "react";
import DesktopMenu from "./DesktopMenu";
import languagePublisher from "../publishers/languagePublisher";
import searchPublisher from "../publishers/searchPublisher";
import mobileMenuPublisher from "../publishers/mobileMenuPublisher";
import MobileMenu from "./MobileMenu";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Header() {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  return (
    <>
      <MobileMenu />
      <div className="header position-absolute w-100" style={{ zIndex: 2 }}>
        <div className="container">
          <div className="d-flex py-2 justify-content-between align-items-center">
            <div>
              <Link to={`/${language}`} className="text-decoration-none">
                <img src="/images/logo.png" className="logo" />
              </Link>
            </div>
            <DesktopMenu />
            <div>
              <i
                className="fa fa-search text-white cursor-pointer"
                onClick={() => searchPublisher.publish(true)}
              ></i>
              <i
                className="fas fa-globe-europe text-white cursor-pointer ml-4"
                onClick={() => languagePublisher.publish(true)}
              ></i>
              <i
                className="fa fa-bars text-white cursor-pointer ml-4"
                onClick={() => mobileMenuPublisher.publish(true)}
              ></i>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;

import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import config from "../config";
import ImageBoxCard2 from "./ImageBoxCard2";
import { useTranslation } from "react-i18next";

function ScrollLoadData({ serverData }) {
  /* const { t, i18n } = useTranslation();
  const language = i18n.language;
  const [category, setCategory] = React.useState([]);
  const [items, setItems] = React.useState([]);
  const [allItems, setAllItems] = React.useState([]);
  const [filters, setFilters] = React.useState([]);
  const [buttonActive, setButtonActive] = React.useState(0);
  const [hasMore, setHasMore] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const length = 8;
  const productRef = React.useRef();

  const fetchData = () => {
    const next = (page + 1) * length;
    const clone = [...category];
    const filterItem = clone.splice(next, length);
    setItems((prev) => prev.concat(filterItem));
    setPage(page + 1);

    if (next > category.length) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  };

  React.useEffect(() => {
    fetch(config.baseAPI + "/products")
      .then((res) => res.json())
      .then((res) => {
        setFilters(res.categories);
        setAllItems(res.products);
        setCategory(res.products);
        const array = [...res.products];
        console.log(res, page, length);
        const f = array.splice(page, length);
        setItems(f);
      });
  }, []);

  const onFilterHandler = (index, id) => {
    setButtonActive(index);
    setPage(0);
    setHasMore(true);

    if (id == 0) {
      const array = [...allItems];
      setCategory(allItems);
      setItems(array.splice(0, length));
    } else {
      const filterCateogry = allItems.filter(
        (item) => item.category.indexOf(id) != -1
      );
      const clone = [...filterCateogry];
      setCategory(filterCateogry);
      setItems(clone.splice(0, length));
    }

    const offsetTop = productRef.current.offsetTop;

    window.scrollTo({
      top: offsetTop,
      left: 0,
      behavior: "smooth",
    });
  }; */

  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const [category, setCategory] = React.useState(serverData.products);
  const allItems = React.useState(serverData.products)[0];
  const filters = React.useState(serverData.categories)[0];
  const [hasMore, setHasMore] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const length = 8;
  const clone = [...category];
  const [items, setItems] = React.useState(() => clone.splice(page, length));
  const [buttonActive, setButtonActive] = React.useState(0);

  const productRef = React.useRef();

  const fetchData = () => {
    const next = (page + 1) * length;
    const clone = [...category];
    const filterItem = clone.splice(next, length);
    setItems((prev) => prev.concat(filterItem));
    setPage(page + 1);
    if (next > category.length) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  };

  const filterHandler = (index, id) => {
    setPage(0);
    setHasMore(true);
    setButtonActive(index);

    if (id == 0) {
      const array = [...allItems];
      setItems(array.splice(0, length));
      setCategory(allItems);
    } else {
      const filterCateogry = allItems.filter(
        (item) => item.category.indexOf(id) != -1
      );
      const clone = [...filterCateogry];
      setCategory(filterCateogry);
      setItems(clone.splice(0, length));
    }
    const offsetTop = productRef.current.offsetTop;

    window.scrollTo({
      top: offsetTop,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="container scroll-load-data">
      <div className="text-center">
        <div
          className="btn-group mb-5 flex-wrap"
          role="group"
          aria-label="Basic example"
        >
          <button
            className={
              "btn btn-outline-secondary py-3 px-5" +
              (buttonActive == 0 ? " active" : "")
            }
            onClick={() => filterHandler(0, 0)}
            style={{ minWidth: 68 }}
          >
            {t("all")}
          </button>

          {filters.map((item, j) => {
            return (
              <button
                key={j}
                className={
                  "btn btn-outline-secondary py-3 px-5" +
                  (buttonActive == j + 1 ? " active" : "")
                }
                onClick={() => filterHandler(j + 1, item.id)}
              >
                {language === "en" ? item.title_en : ""}
                {language === "tc" ? item.title_tc : ""}
                {language === "sc" ? item.title_sc : ""}
              </button>
            );
          })}
        </div>
      </div>
      <InfiniteScroll
        dataLength={items.length} //This is important field to render the next data
        next={fetchData}
        hasMore={hasMore}
        style={{ overflow: "hidden" }}
        /*     loader={<h4>Loading...</h4>}
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>Yay! You have seen it all</b>
          </p>
        } */

        // below props only if you need pull down functionality
        /*  refreshFunction={this.refresh} */
        /*  pullDownToRefresh
                pullDownToRefreshThreshold={50}
                pullDownToRefreshContent={
                    <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
                }
                releaseToRefreshContent={
                    <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
                } */
      >
        <div className="row" ref={productRef}>
          {items.map((item, index) => (
            <ImageBoxCard2 key={index} data={item} />
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
}

export default ScrollLoadData;

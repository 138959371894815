import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import languagePublisher from "../publishers/languagePublisher";

function Language() {
  const history = useHistory();
  const pathname = history.location.pathname;
  const [open, setOpen] = React.useState(false);
  const { i18n } = useTranslation();

  const changeLanguageHandle = (language) => {
    i18n.changeLanguage(language);
    const path = pathname.split("/");
    path.shift();
    path[0] = language;
    const url = "/" + path.join("/");
    document.body.classList.remove("en", "tc", "sc");
    document.body.classList.add(language);
    setOpen(false);
    history.push(url);
  };

  const currentLanguage = i18n.language;
  const urlLanguage = pathname.split("/")[1];

  React.useEffect(() => {
    languagePublisher.subscribe("languagePlanform", (state) => {
      setOpen(state);
    });

    if (urlLanguage !== "en" && urlLanguage !== "sc" && urlLanguage !== "tc") {
      history.push("/en");
    } else if (currentLanguage !== urlLanguage) {
      changeLanguageHandle(urlLanguage);
    }
  }, []);

  return (
    <div className={"language" + (open ? " active" : "")}>
      <div className="language-close" onClick={() => setOpen(false)}></div>
      <div className="language-content">
        <div className="text-center m-4 mt-5">
          <div
            className="btn btn-outline-secondary ll-btn"
            onClick={() => changeLanguageHandle("en")}
          >
            English
          </div>
        </div>
        <div className="text-center m-4">
          <div
            className="btn btn-outline-secondary ll-btn"
            onClick={() => changeLanguageHandle("tc")}
          >
            繁體
          </div>
        </div>
        <div className="text-center m-4">
          <div
            className="btn btn-outline-secondary ll-btn"
            onClick={() => changeLanguageHandle("sc")}
          >
            简体
          </div>
        </div>
      </div>
    </div>
  );
}

export default Language;

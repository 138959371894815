import React from 'react'

function ContactUsInfo() {
    return (
        <div className="contact-us-info">
            <div className="mb-5">
                <h2 className="mb-3">
                    Hong Kong Office 
                </h2>
                <div className="cui-content">
                    <div className="color-grey">
                        Unit 04, 7/F, Grand Business Plaza,
                    </div>
                    <div className="color-grey">
                        153 Wai Yip Street, Kwun Tong, Kowloon, Hong Kong
                    </div>
                    <div className="color-blue">
                        Tel: (852) 2421 6728
                    </div>
                </div>
            </div>
            <div>
                <h2 className="mb-3">
                    China Factory 
                </h2>
                <div className="cui-content">
                    <div className="color-grey">
                        2/F, No. 11 Daji Industrial, Hengshan Village, Shipai Town,
                    </div>
                    <div className="color-grey">
                        Dongguan City, Guangdong Province, 523330, China
                    </div>
                    <div className="color-blue">
                        Tel: (86) 769 8182 9076
                    </div>
                    <div className="color-blue">
                        Email: sales@tongyeung.com
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUsInfo

import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, Link } from "react-router-dom";
import mobileMenuPublisher from "../publishers/mobileMenuPublisher";

function MobileMenu() {
  const history = useHistory();
  const pathname = history.location.pathname;
  const [open, setOpen] = React.useState(false);
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const closeMobileMenuHandle = () => {
    window.scroll(0, 0);
    setOpen(false);
  };

  React.useEffect(() => {
    mobileMenuPublisher.subscribe("mobileMenuPlanform", (state) => {
      setOpen(state);
    });
  }, []);

  return (
    <div className={"mobile-menu" + (open ? " active" : "")}>
      <div className="mobile-menu-close" onClick={() => setOpen(false)}></div>
      <div className="mobile-menu-content">
        <Link
          className="d-block text-center text-capitalize my-2"
          to={`/${language}`}
          onClick={closeMobileMenuHandle}
        >
          {t("home")}
        </Link>
        <Link
          className="d-block text-center text-capitalize my-2"
          to={`/${language}/about-us`}
          onClick={closeMobileMenuHandle}
        >
          {t("about_us")}
        </Link>
        <Link
          className="d-block text-center text-capitalize my-2"
          to={`/${language}/products`}
          onClick={closeMobileMenuHandle}
        >
          {t("product_gallery")}
        </Link>
        <Link
          className="d-block text-center text-capitalize my-2"
          to={`/${language}/wine-packaging`}
          onClick={closeMobileMenuHandle}
        >
          {t("wine_packaging")}
        </Link>
        <Link
          className="d-block text-center text-capitalize my-2"
          to={`/${language}/contact-us`}
          onClick={closeMobileMenuHandle}
        >
          {t("contact_us")}
        </Link>
      </div>
    </div>
  );
}

export default MobileMenu;

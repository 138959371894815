import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import config from "../config";
import ImageBoxCard2 from "./ImageBoxCard2";
import { useTranslation } from "react-i18next";
import CategoryBoxImage from "../components/CategoryBoxImage";
import ImageBoxCard3 from "./ImageBoxCard3";

function ScrollLoadData2({ serverData }) {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const [category, setCategory] = React.useState(serverData.products);
  const allItems = React.useState(serverData.products)[0];
  const filters = React.useState(serverData.categories)[0];
  const [hasMore, setHasMore] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const length = 6;
  const clone = [...category];
  const [items, setItems] = React.useState(() => clone.splice(page, length));

  const productRef = React.useRef();

  const fetchData = () => {
    const next = (page + 1) * length;
    const clone = [...category];
    const filterItem = clone.splice(next, length);
    setItems((prev) => prev.concat(filterItem));
    setPage(page + 1);
    if (next > category.length) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  };

  const filterHandler = (index, id) => {
    setPage(0);
    setHasMore(true);

    if (id == 0) {
      const array = [...allItems];
      setItems(array.splice(0, length));
      setCategory(allItems);
    } else {
      const filterCateogry = allItems.filter(
        (item) => item.category.indexOf(id) != -1
      );
      const clone = [...filterCateogry];
      setCategory(filterCateogry);
      setItems(clone.splice(0, length));
    }
    const offsetTop = productRef.current.offsetTop;

    window.scrollTo({
      top: offsetTop,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="scroll-load-data" style={{ backgroundColor: "#e9f4fa" }}>
        <div className="container text-center py-5">
          <h1 className="mb-5">{t("styles")}</h1>
          <div className="row">
            <CategoryBoxImage
              title={t("all")}
              source={`${config.baseURL}/storage/${serverData.page.thumbnail}`}
              filterHandler={() => filterHandler(0, 0)}
            />

            {filters.map((item, j) => {
              return (
                <CategoryBoxImage
                  key={j}
                  title={item["title_" + language]}
                  source={`${config.baseURL}/storage/${item.thumbnail}`}
                  filterHandler={() => filterHandler(j + 1, item.id)}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className="container my-5">
        <h1 className="mb-5 text-center text-capitalize">{t("products")}</h1>
        <InfiniteScroll
          dataLength={items.length} //This is important field to render the next data
          next={fetchData}
          hasMore={hasMore}
          style={{ overflow: "hidden" }}
          /*     loader={<h4>Loading...</h4>}
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>Yay! You have seen it all</b>
          </p>
        } */

          // below props only if you need pull down functionality
          /*  refreshFunction={this.refresh} */
          /*  pullDownToRefresh
                pullDownToRefreshThreshold={50}
                pullDownToRefreshContent={
                    <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
                }
                releaseToRefreshContent={
                    <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
                } */
        >
          <div className="row" ref={productRef}>
            {items.map((item, index) => (
              <ImageBoxCard3 key={index} data={item} />
            ))}
          </div>
        </InfiniteScroll>
      </div>
    </>
  );
}

export default ScrollLoadData2;

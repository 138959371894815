import React, { Suspense } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Header from "./partials/Header";
import Language from "./partials/Language";
import Loading from "./partials/Loading";
import SearchPlatform from "./partials/SearchPlatform";
import Home from "./screens/Home";
import AboutUs from "./screens/AboutUs";
import ContactUs from "./screens/ContactUs";
import Products from "./screens/Products";
import WinePackaging from "./screens/WinePackaging";
import WineDetail from "./screens/WineDetail";
import Footer from "./partials/Footer";
import ProductDetail from "./screens/ProductDetail";
import PageNotFound from "./screens/PageNotFound";
import SocialMedia from "./partials/SocialMedia";
import { resource } from "./fetchDataApp";
import BackToTop from "./partials/BackToTop";
import SearchResult from "./screens/SearchResult";

function App() {
  console.log("app....");
  return (
    <Suspense fallback={<Loading />}>
      <div className="App">
        <BrowserRouter>
          <Header />
          <Language />
          <SearchPlatform />
          <Switch>
            <Redirect exact from="/" to="/en" />
            <Route exact path="/:language" component={Home} />
            <Route
              path="/:language/search/:text"
              render={(props) => {
                const language = props.match.params.language;
                const text = props.match.params.text;
                const server = resource.searchProduct(language, text);
                return <SearchResult server={server} {...props} />;
              }}
            />
            <Route path="/:language/about-us" component={AboutUs} />
            <Route path="/:language/contact-us" component={ContactUs} />
            <Route
              exact
              path="/:language/products"
              render={(props) => {
                window.scrollTo(0, 0);
                return <Products {...props} />;
              }}
            />
            <Route
              path="/:language/products/:id"
              render={(props) => {
                const id = props.match.params.id;
                const server = resource.productDetail(id);
                return <ProductDetail server={server} {...props} />;
              }}
            />
            <Route
              exact
              path="/:language/wine-packaging"
              component={WinePackaging}
            />
            <Route
              path="/:language/wine-packaging/:id"
              render={(props) => {
                const id = props.match.params.id;
                const server = resource.wineDetail(id);
                return <WineDetail server={server} {...props} />;
              }}
            />
            <Route path="/404/page-not-found" component={PageNotFound} />
            <Route path="*" component={PageNotFound} />
          </Switch>
          <Footer />
          <SocialMedia />
        </BrowserRouter>
      </div>
      <BackToTop />
    </Suspense>
  );
}

export default App;

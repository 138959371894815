import React from "react";

function ProductsIntroduct({ subtitle, title, content }) {
  return (
    <div className="products-introduct mt-4">
      <div className="container text-center">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8">
            {subtitle && (
              <small className="pi-small-title color-grey">{subtitle}</small>
            )}
            {title && <h2 className="pi-title">{title}</h2>}
            <div className="pi-line"></div>
            <div
              className="pi-content"
              dangerouslySetInnerHTML={{ __html: content }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductsIntroduct;

import React from "react";
import { Link, useParams } from "react-router-dom";

function Gallery4column({ data }) {
  const { language } = useParams();

  return (
    <div className="gallery-4-column my-5">
      <div className="container">
        <div className="row">
          {data.map((item, index) => (
            <div key={index} className="col-12 col-md-6 col-lg-3 mb-4">
              {item.type === "image" && item.id && (
                <>
                  <Link
                    to={
                      language +
                      `/` +
                      (item.itemType == "product"
                        ? "products"
                        : "wine-packaging") +
                      "/" +
                      item.id
                    }
                    className="g4c-inner mb-3"
                    style={{
                      backgroundColor: "#666",
                      backgroundImage: "url(" + item.src + ")",
                    }}
                  ></Link>
                  <Link
                    to={
                      language +
                      `/` +
                      (item.itemType == "product"
                        ? "products"
                        : "wine-packaging") +
                      "/" +
                      item.id
                    }
                    className="text-center mt-3 text-decoration-none text-dark"
                    style={{ top: 20 }}
                  >
                    {item.title}
                  </Link>
                </>
              )}
              {item.type === "video" && item.id && (
                <>
                  <Link
                    to={
                      language +
                      `/` +
                      (item.itemType == "product"
                        ? "products"
                        : "wine-packaging") +
                      "/" +
                      item.id
                    }
                    className="g4c-inner mb-3"
                    style={{ backgroundColor: "#666" }}
                  >
                    <video width="100%" height="100%" controls>
                      <source src={item.src} type="video/mp4" />
                    </video>
                  </Link>
                  <Link
                    to={
                      language +
                      `/` +
                      (item.itemType == "product"
                        ? "products"
                        : "wine-packaging") +
                      "/" +
                      item.id
                    }
                    className="text-center mt-3 text-decoration-none text-dark"
                    style={{ top: 20 }}
                  >
                    {item.title}
                  </Link>
                </>
              )}

              {item.type === "video" && !item.id && (
                <>
                  <div
                    className="g4c-inner mb-3"
                    style={{ cursor: "default", backgroundColor: "#666" }}
                  >
                    <video width="100%" height="100%" controls>
                      <source src={item.src} type="video/mp4" />
                    </video>
                  </div>
                  <div className="text-center mt-3" style={{ top: 20 }}>
                    {item.title}
                  </div>
                </>
              )}

              {item.type === "image" && !item.id && (
                <>
                  <div
                    className="g4c-inner mb-3"
                    style={{
                      backgroundColor: "#666",
                      backgroundImage: "url(" + item.src + ")",
                      cursor: "default",
                    }}
                  ></div>
                  <div className="text-center mt-3" style={{ top: 20 }}>
                    {item.title}
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Gallery4column;

import React from "react";
import Banner from "../components/Banner";
import Breadcrumb from "../partials/Breadcrumb";
import config from "../config";
import { useTranslation } from "react-i18next";
import ContactUsForm from "../components/ContactUsForm";
import ContactUsInfo from "../components/ContactUsInfo";
import { resource } from "../fetchDataApp";
import $ from "jquery";
import DocumentMeta from "react-document-meta";

function ContactUs(props) {
  const serverData = resource.contactUsPage.read();
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const bannerDataItem = {
    src: `${config.baseURL}/storage/${serverData.banner}`,
  };
  const breadcrumbData = [
    { title: t("home"), link: "/" },
    { title: t("contact_us") },
  ];

  window.scroll(0, 0);

  React.useEffect(() => {
    $(function () {
      if (props.location.search === "?scroll=true") {
        const offsetTop = $(".banner").height();
        $("html, body").animate(
          {
            scrollTop: offsetTop,
          },
          400
        );
      }
    });
  }, []);

  const meta = {
    title:
      t("contact_us").toUpperCase() + " | " + t("tong_yeung").toUpperCase(),
  };

  return (
    <DocumentMeta {...meta}>
      <div className="contact-us">
        <Banner data={bannerDataItem} />
        <Breadcrumb data={breadcrumbData} />
        <div className="container my-5">
          <div className="row">
            <div className="col-12 col-lg-6 mb-5">
              <div
                dangerouslySetInnerHTML={{
                  __html: serverData["content_" + language],
                }}
              ></div>
            </div>
            <div className="col-12 col-lg-6">
              <ContactUsForm />
            </div>
          </div>
        </div>
      </div>
    </DocumentMeta>
  );
}

export default ContactUs;
